import { useState, useEffect } from "react";
import { Button, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { MenuItem, HomeLink } from "../ui";
import { ScenarioNavigationItem } from "../../types";

export type Props = {
  navigation: ScenarioNavigationItem[];
  mainPage: { key: string; id: string };
  currentPageKey: string | undefined;
  onClickHomeLink: () => void;
};

const triggerCss = {
  background: "#ff733b",
  borderRadius: "100%",
  position: "absolute",
  right: "15px",
  top: "25px",
  fontSize: "20px",
  padding: "0.5rem",
  color: "#fff",
  zIndex: 15,
  outline: "none",
  boxShadow: "0 5px 10px 0 rgb(1 10 39 / 20%)",
  minWidth: "auto",
  "&:hover": {
    bgcolor: "#ff733b",
    color: "#fff",
  },
};

const triggerActiveCss = {
  background: "#fff",
  color: "#ff733b",
};

const iconCss = {
  width: "1.75rem",
  height: "1.75rem",
};

const backdropCss = {
  background: "rgba(0, 0, 0, 0.8)",
  color: "#fff",
  height: "100vh",
  width: "100vw",
  position: "fixed",
  zIndex: 10,
};

const menuWrapperCss = {
  background: "#ff733b",
  color: "#fff",
  height: "100vh",
  width: "100vw",
  position: "fixed",
  top: 0,
  right: 0,
  zIndex: 12,
  padding: "0 1.5rem 3rem",
  overflowY: "auto",

  "@media(min-width: 30em)": {
    width: "25vw",
  },
};

const menuTitleCss = {
  color: "#fff",
  padding: "1.9rem 0 4rem",
  fontSize: "1.5rem",
  fontWeight: 800,
};

function getActiveIntentIds(
  menuIntents: { [key: string]: ScenarioNavigationItem[] },
  currentPageKey: string
): ScenarioNavigationItem["id"][] {
  // set to true to enter in the while in the first iteration
  let hasFoundAParent = true;
  let searchedPageKey = currentPageKey;
  const activeIntentIds: ScenarioNavigationItem["id"][] = [];

  // menuIntents is an object where keys = items and values = intents of the given item
  // here, we create the list of the intents
  let listOfIntents: (ScenarioNavigationItem & { hasBeenSeen: boolean })[] = [];
  for (const [, intents] of Object.entries(menuIntents))
    listOfIntents = [
      ...listOfIntents,
      ...intents.map((intent) => ({ ...intent, hasBeenSeen: false })),
    ];
  while (hasFoundAParent === true) {
    hasFoundAParent = false;
    for (let i = 0; i < listOfIntents.length; i += 1)
      if (
        listOfIntents[i].hasBeenSeen !== true &&
        (listOfIntents[i].to?.key === searchedPageKey ||
          listOfIntents[i].fromKey === currentPageKey)
      ) {
        hasFoundAParent = true;
        // to avoid loop, if there is a loop between two pages
        listOfIntents[i].hasBeenSeen = true;
        activeIntentIds.push(listOfIntents[i].id);
        if (listOfIntents[i].to?.key === searchedPageKey)
          searchedPageKey = listOfIntents[i].fromKey;
      }
  }
  return activeIntentIds;
}
function getIntentByPageKey(intents: ScenarioNavigationItem[]): {
  [key: string]: ScenarioNavigationItem[];
} {
  const intentsByPageKey: { [key: string]: ScenarioNavigationItem[] } = {};
  intents.forEach((intent) => {
    try {
      if (intentsByPageKey[intent.fromKey] != null) intentsByPageKey[intent.fromKey].push(intent);
      else intentsByPageKey[intent.fromKey] = [intent];

      // eslint-disable-next-line
    } catch (_) {}
  });
  return intentsByPageKey;
}

const Menu = ({ navigation, mainPage, onClickHomeLink, currentPageKey }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const intents = navigation
    .filter((intent) => {
      return intent.fromKey === mainPage.key;
    })
    .sort((intent, intent2) => {
      if (intent.blockPosition != null && intent2.blockPosition != null) {
        if (intent.blockPosition === intent2.blockPosition)
          return intent.position - intent2.position;

        return intent.blockPosition - intent2.blockPosition;
      }
      if (intent.blockPosition != null) return intent.blockPosition - intent2.position;

      if (intent2.blockPosition != null) return intent.position - intent2.blockPosition;

      return intent.position - intent2.position;
    });
  const intentsByPageKey = getIntentByPageKey(navigation);
  //   TODO: GET THE REAL CURRENT PAGE KEY
  const activeIntentIds = getActiveIntentIds(intentsByPageKey, currentPageKey || "");

  // I think we should change #root to #__next, as there is no root id in the DOM
  const root = document.querySelector<HTMLElement>("#root");

  useEffect(() => {
    if (root != null && isOpen) root.style.overflowY = "hidden";
    else if (root != null) root.style.overflowY = "auto";
    // eslint-disable-next-line
  }, [isOpen]);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  /* eslint-disable */
  return (
    <Box>
      {!isOpen && (
        <Button type="button" sx={triggerCss} onClick={handleClick} className="ich-button-menu">
          <MenuIcon sx={iconCss} />
        </Button>
      )}
      {isOpen && (
        <>
          <Box sx={backdropCss} />
          <Box sx={menuWrapperCss} className="ich-wrapper-menu">
            <Button
              type="button"
              sx={[triggerCss, triggerActiveCss]}
              onClick={handleClick}
              className="ich-button-menu"
            >
              <CloseIcon sx={iconCss} />
            </Button>
            <Box sx={menuTitleCss}>Menu</Box>
            <HomeLink
              onClick={() => {
                setIsOpen(false);
                onClickHomeLink();
              }}
            />
            {intents.map((intent) => (
              <MenuItem
                noMarginBottom={false}
                activeIntentIds={activeIntentIds}
                key={intent.id}
                intent={intent}
                menuIntents={intentsByPageKey}
                onLinkClick={() => {
                  setIsOpen(false);
                }}
              />
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Menu;
