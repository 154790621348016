import React, { forwardRef, ForwardedRef, useState } from "react";
import {
  LocalizationProvider as MUILocalizationProvider,
  DatePicker as MUIDatePicker,
  DatePickerProps as MUIDatePickerProps,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextFieldProps as MUITextFieldProps } from "@mui/material";
import { UseDateFieldProps } from "@mui/x-date-pickers/DateField";
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
} from "@mui/x-date-pickers/models";
import { Button, ButtonProps } from "../Button";
import { format as dateFormat } from "date-fns";
import { fr, enGB } from "date-fns/locale";
import { defaultLanguage } from "../../../constants/language";
import { Language } from "../../../types/language";

interface ButtonFieldProps
  extends Pick<ButtonProps, "variant" | "color" | "sx" | "startIcon" | "endIcon">,
    UseDateFieldProps<Date>,
    BaseSingleInputFieldProps<Date | null, Date, FieldSection, DateValidationError> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  hideDate?: boolean;
}

type TextFieldProps = Omit<
  MUITextFieldProps,
  "size" | "color" | "variant" | "select" | "margin" | "type"
>;

export type DatePickerProps = MUIDatePickerProps<Date> & {
  type?: "input" | "button";
  fieldProps?:
    | TextFieldProps
    | (ButtonProps & {
        hideDate?: boolean;
      });
  langage?: Language;
};

type SlotsProps = Pick<DatePickerProps, "slots" | "slotProps" | "open" | "onClose" | "onOpen">;

const languages = {
  [Language.fr]: fr,
  [Language.en]: enGB,
};

const localeText = {
  [Language.fr]: {
    clearButtonLabel: "Réinitialiser",
    todayButtonLabel: "Aujourd'hui",
  },
  [Language.en]: {
    clearButtonLabel: "Clear",
    todayButtonLabel: "Today",
  },
};

function ButtonField(props: ButtonFieldProps) {
  const {
    setOpen,
    format,
    label,
    value,
    id,
    disabled,
    hideDate = false,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;

  return (
    <Button
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      variant={props?.variant}
      color={props?.color}
      sx={props?.sx}
      startIcon={props?.startIcon}
      endIcon={props?.endIcon}
    >
      {value
        ? `${label || "Date"}${!hideDate ? `: ${dateFormat(value, format ?? "dd/MM/yyyy")}` : ""}`
        : label}
    </Button>
  );
}

export const DatePicker = forwardRef(
  (
    {
      type = "input",
      value,
      fieldProps,
      slotProps,
      langage = defaultLanguage,
      format,
      ...props
    }: DatePickerProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [open, setOpen] = useState(false);

    const slotsProps: SlotsProps = {};

    if (type === "button") {
      slotsProps.slotProps = {
        field: { format, setOpen, ...(fieldProps as ButtonProps) } as any,
        ...slotProps,
      };
      slotsProps.slots = { field: ButtonField };
      slotsProps.open = open;
      slotsProps.onClose = () => setOpen(false);
      slotsProps.onOpen = () => setOpen(true);
    } else if (type === "input") {
      slotsProps.slotProps = {
        textField: { size: "small", ...(fieldProps as TextFieldProps) },
        ...slotProps,
      };
    }

    return (
      <MUILocalizationProvider
        localeText={{
          clearButtonLabel: localeText[langage].clearButtonLabel,
          todayButtonLabel: localeText[langage].todayButtonLabel,
        }}
        dateAdapter={AdapterDateFns}
        adapterLocale={languages[langage]}
      >
        <MUIDatePicker ref={ref} value={value} format={format} {...slotsProps} {...props} />
      </MUILocalizationProvider>
    );
  }
);
