import { PaletteColor, PaletteColorOptions } from "@mui/material/styles";

import { DefaultTheme, DefaultThemeParams, DegType, getBaseLinearGradient } from "./newTheme";

declare module "@mui/material/styles" {
  interface Palette {
    tertiaryBlue?: PaletteColor;
    tertiaryPink?: PaletteColor;
  }
  interface PaletteOptions {
    tertiaryBlue?: PaletteColorOptions;
    tertiaryPink?: PaletteColorOptions;
  }
}

const PRIMARY_DARK = "#E47C71";
const PRIMARY_MAIN = "#ED614C";
const PRIMARY_LIGHT = "#F05630";

const SECONDARY_DARK = "#3A3E56";
const SECONDARY_MAIN = "#262A3E";
const SECONDARY_LIGHT = "#191C2A";

const TERTIARY_BLUE_DARK = "#0044D5";
const TERTIARY_BLUE_MAIN = "#4262DD";
const TERTIARY_BLUE_LIGHT = "#697EE3";

const TERTIARY_PINK_DARK = "#CC22A3";
const TERTIARY_PINK_MAIN = "#D756B2";
const TERTIARY_PINK_LIGHT = "#E48AC8";

export const getDoLinearGradient = (deg: DegType = "r") => {
  return getBaseLinearGradient(
    deg,
    `${TERTIARY_BLUE_MAIN} 0%, ${TERTIARY_PINK_MAIN} 50%, ${PRIMARY_MAIN} 100%`
  );
};

export const DO_LINEAR_GRADIENT = getDoLinearGradient();

const BLACK = "#030312";

const GREY_50 = "#F7F7FA";
const GREY_100 = "#F2F2F5";
const GREY_200 = "#ECECF1";
const GREY_300 = "#D5D5DF";
const GREY_400 = "#A7A7B8";
const GREY_500 = "#727286";
const GREY_600 = "#55546B";
const GREY_700 = "#40405A";
const GREY_800 = "#292941";
const GREY_900 = "#111126";

const HEADER_FONT_FAMILY = `'Poppins', 'Roboto', Arial, Segoe UI, sans-serif`;
const TEXT_FONT_FAMILY = `'DM Sans', 'Roboto', Public, sans-serif`;

const themeParams: DefaultThemeParams = {
  palette: {
    primaryDark: PRIMARY_DARK,
    primaryMain: PRIMARY_MAIN,
    primaryLight: PRIMARY_LIGHT,

    secondaryDark: SECONDARY_DARK,
    secondaryMain: SECONDARY_MAIN,
    secondaryLight: SECONDARY_LIGHT,

    extensions: {
      tertiaryBlue: {
        dark: TERTIARY_BLUE_DARK,
        main: TERTIARY_BLUE_MAIN,
        light: TERTIARY_BLUE_LIGHT,
      },

      tertiaryPink: {
        dark: TERTIARY_PINK_DARK,
        main: TERTIARY_PINK_MAIN,
        light: TERTIARY_PINK_LIGHT,
      },
    },

    black: BLACK,

    grey50: GREY_50,
    grey100: GREY_100,
    grey200: GREY_200,
    grey300: GREY_300,
    grey400: GREY_400,
    grey500: GREY_500,
    grey600: GREY_600,
    grey700: GREY_700,
    grey800: GREY_800,
    grey900: GREY_900,
  },

  font: {
    header: HEADER_FONT_FAMILY,
    text: TEXT_FONT_FAMILY,
  },
};
const themeObj = new DefaultTheme(themeParams);

export const doTheme = themeObj.create();

export type DoThemeType = typeof doTheme;
