import { Box, GlobalStyles } from "@mui/material";
import { UseQueryResult, useQueries } from "@tanstack/react-query";
import Head from "next/head";
import { NextRouter } from "next/router";
import { NextComponentType, NextPageContext } from "next/types";
import { FC, useEffect, useState } from "react";
import {
  ContextKeysRecord,
  DynamicUrls,
  PushWrapperFn,
  ScenarioAnalyticsDocumentActionType,
  ScenarioData,
  ScenarioDetails,
} from "../types";
import globalStyle from "./assets/globalStyle";
import { STORAGE_HISTORY_IDX_KEY } from "./constants";
import {
  PostScenarioDetailsPayload,
  getAllQueries,
  getContextFromContrack,
  getCurrentBranch,
  getCurrentIntegrationType,
  getInSessionStorage,
  getNavigationWithRouter,
  handleBackButtonClick,
  handleCloseButtonClick,
  handleHomeButtonClick,
  isDataReady,
  postScenarioDetails,
  setInSessionStorage,
  toMongoId,
} from "./helpers";
import { context as contentContext, scenarioParams } from "./stores";
import { ExternalScripts, Footer, Header, Spinner, WaterMark } from "./ui";

export type FilteredResultType = {
  scenarioDetails: ScenarioDetails;
  scenarioData: ScenarioData;
};

type ComponentProps = {
  scenarioId?: string;
  shortCode?: string;
  currentIntegrationType: string;
  branch: string;
  router: NextRouter;
  dynamicUrls: DynamicUrls;
  filteredResult: {
    scenarioDetails: ScenarioDetails;
    scenarioData: ScenarioData;
  };
  mongoSolutionId: string;
  routerPush: PushWrapperFn;
  contextRecord?: ContextKeysRecord;
  jwt?: string;
};

type Props = {
  Component: NextComponentType<NextPageContext, Record<string, unknown>, ComponentProps>;
  router: NextRouter;
};

type RouterQuery = {
  query: {
    scenarioId?: string;
    shortCode?: string;
    integrationType?: IntegrationType;
    branch?: string;
    pageKey?: string;
    solutionId?: string;
  };
};

export enum IntegrationType {
  WIDGET = "widget",
  VOICE = "voice",
  HYPERLINK = "hyperlink",
}

// DATA LOADER OU DATA FETCHER
const DataLoader: FC<Props> = ({ Component, router }) => {
  const [detailsData, setDetailsData] = useState<ScenarioDetails>();
  const [analyticsAreReady, setAnalyticsAreReady] = useState(false);
  let cookieAccepted = "0";

  if (typeof window !== "undefined") {
    const queryParams = new URL(window.location.href).searchParams;

    // Store all queryParams for further use
    for (const [key, value] of queryParams.entries()) {
      setInSessionStorage(key, value);
    }

    cookieAccepted = getInSessionStorage("showBanner") === "false" ? "1" : "0";
    const cookieAcceptedInStorage = getInSessionStorage("cookieAccepted");

    if (cookieAccepted !== cookieAcceptedInStorage)
      setInSessionStorage("cookieAccepted", cookieAccepted);
  }

  const {
    query: { scenarioId, shortCode, integrationType, branch, pageKey, solutionId },
  } = router as RouterQuery;

  const solution = !!solutionId;
  const preview = !shortCode;

  const currentIntegrationType = getCurrentIntegrationType({
    currentIntegrationTypeFromPropsOrSessionStorage:
      integrationType || (getInSessionStorage("integrationType") as IntegrationType | null),
  });

  const queries = getAllQueries({
    shortCode,
    scenarioId,
    currentIntegrationType,
    solution,
    solutionId,
    pageKey,
    preview,
    detailsData,
  });

  const scenarioDetails = detailsData;
  const results = useQueries({ queries }) as UseQueryResult<{
    scenarioDetails: ScenarioDetails;
    scenarioData: ScenarioData;
  }>[];

  const isLoading = results.some(({ isLoading }: { isLoading: boolean }) => isLoading);
  const isError = results.some(({ isError }: { isError: boolean }) => isError);

  useEffect(() => {
    if (!scenarioDetails && isDataReady(isLoading, isError, results, queries)) {
      const filteredResult = results.find((r) => r?.data?.scenarioDetails);
      if (filteredResult?.data) setDetailsData(filteredResult.data.scenarioDetails);
    }
  }, [isLoading, isError, results, queries, scenarioDetails, setDetailsData]);

  useEffect(() => {
    if (shortCode && detailsData?.mainPage)
      postScenarioDetails({
        shortCode: shortCode as string,
        toPageKey: detailsData.mainPage.key,
        type: ScenarioAnalyticsDocumentActionType.Load,
      })
        .then(() => {
          setAnalyticsAreReady(true);
        })
        .catch((e) => {
          //TODO better error handling
          console.error(e);
        });
  }, [shortCode, detailsData]);

  // The scenarioSessionId is not set in session storage yet, so the interface shouldn't launch
  const shouldWaitForAnalytics = shortCode && !analyticsAreReady;

  if (
    !isDataReady(isLoading, isError, results, queries) ||
    !scenarioDetails ||
    shouldWaitForAnalytics
  )
    return (
      <div className="centeredSpinner">
        <Spinner />
      </div>
    );

  const { context, isPreview, mainPage, navigation, previewContext, scripts, settings, jwt } =
    scenarioDetails;
  const dynamicUrls = context.dynamicUrls;
  const contextRecord = context.contexts;
  const { header, footer, style } = context.layout;
  const currentBranch = getCurrentBranch({
    branchFromProps: branch,
    defaultValue: context.dynamicUrls.defaultValue,
  });

  // preview mode, set preview context as context
  if (preview && previewContext) {
    contentContext.value = previewContext;
  }

  const { pinCode } = scenarioParams.value;

  // not preview mode, get context from contrack
  if (shortCode && scenarioDetails.id && pinCode && !preview) {
    getContextFromContrack(shortCode, pinCode)
      .then((response) => {
        if (response.ok) {
          response
            .json()
            .then((JSONResponse) => {
              contentContext.value = JSONResponse.body.context;
            })
            .catch((e) => {
              //TODO better error handling
              console.error(e);
            });
        }
        // erasing context if it expires during a session
        if (response.status === 404) {
          contentContext.value = {};
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  if (!pageKey && !solution) {
    const url = shortCode ? `/c/${shortCode}/${mainPage.key}` : `/p/${scenarioId}/${mainPage.key}`;
    router.push(url).catch((e) => {
      //TODO better error handling
      console.error(e);
    });
  }

  const routerPush: PushWrapperFn = async (url, opt) => {
    // Allow to make the navigation index history persistent in order to manage the back button
    const historyIdx = getInSessionStorage(STORAGE_HISTORY_IDX_KEY);
    if (historyIdx) {
      const historyIdxNumber: number = parseInt(historyIdx) + 1;
      setInSessionStorage(STORAGE_HISTORY_IDX_KEY, historyIdxNumber.toString());
    } else {
      setInSessionStorage(STORAGE_HISTORY_IDX_KEY, "0");
    }

    const { toPageKey, fromIntentId } = opt || {};

    const analytics: PostScenarioDetailsPayload = {
      type: ScenarioAnalyticsDocumentActionType.Push,
      ...(pageKey && { fromPageKey: pageKey }),
      ...(toPageKey && { toPageKey }),
      ...(fromIntentId && { fromIntentId }),
      ...(shortCode && { shortCode }),
    };

    await postScenarioDetails(analytics);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await router.push({ pathname: url }, url, { previousPageKey: pageKey, shallow: true });
  };

  const navigationWithRouter = getNavigationWithRouter({
    navigation,
    routerPush,
    shortCode,
    scenarioId,
    branch,
    dynamicUrls,
  });

  const filteredResult = results.find((r) => r?.data?.scenarioData);

  if (!filteredResult?.data) return <Spinner />;
  if (filteredResult.data.scenarioData.children && cookieAccepted === "1")
    filteredResult.data.scenarioData.children = filteredResult.data.scenarioData.children.filter(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      (c) => !c.view?.includes("ich3-cookie-banner")
    );

  const externalScripts = pageKey && (
    <ExternalScripts scripts={scripts} shortCode={shortCode} currentPageKey={pageKey} />
  );

  const onClickBackButton = async () =>
    handleBackButtonClick({ back: router.back, pageKey, solutionId, shortCode });

  const onClickHomeButton = () =>
    handleHomeButtonClick({ shortCode, scenarioId, mainPage, routerPush });

  const onClickCloseButton = () =>
    handleCloseButtonClick({ shortCode, toPageKey: pageKey, solutionId });

  return (
    <Box className="ich-layout" style={{ position: "relative" }}>
      <Head>
        <title>{settings?.documentTitle || "Dialonce"}</title>
        {settings?.faviconUrl && <link rel="shortcut icon" href={settings.faviconUrl} />}
      </Head>
      {isPreview && <WaterMark />}
      <Header
        header={header}
        navigation={navigationWithRouter}
        mainPage={mainPage}
        currentPageKey={pageKey}
        settings={settings}
        router={router}
        onClickBackButton={onClickBackButton}
        onClickHomeButton={onClickHomeButton}
        onClickCloseButton={onClickCloseButton}
      />
      <Component
        scenarioId={scenarioId}
        shortCode={shortCode}
        currentIntegrationType={currentIntegrationType}
        branch={currentBranch}
        router={router}
        dynamicUrls={dynamicUrls}
        filteredResult={filteredResult.data}
        mongoSolutionId={toMongoId(solutionId ?? "")}
        routerPush={routerPush}
        contextRecord={contextRecord}
        jwt={jwt}
      />
      <Footer content={footer} />
      <GlobalStyles styles={`${globalStyle} ${style}`} />
      {externalScripts}
    </Box>
  );
};

export default DataLoader;
