import type { MenuProps } from "@mui/material/Menu";
import type { SxProps } from "@mui/system";
import type { ButtonProps } from "../Button";

import { ExpandMoreRounded } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import React, { forwardRef, ReactNode, useState } from "react";
import { Button } from "../Button";
import { IconMenuItem } from "./IconMenuItem";
import { NestedMenuItem } from "./NestedMenuItem";

export interface MenuItemData {
  callback?: (event: React.MouseEvent<HTMLElement>, item: MenuItemData) => void;
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  items?: MenuItemData[];
  label?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  sx?: SxProps;
  uid?: string;
}

export interface NestedDropdownProps {
  ButtonProps?: Partial<ButtonProps>;
  children?: React.ReactNode;
  items?: MenuItemData[];
  label: string;
  MenuProps?: Partial<MenuProps>;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  setItems?: React.Dispatch<React.SetStateAction<MenuItemData[]>>;
}

interface nestedMenuItemsFromObjectProps {
  handleClose: () => void;
  isOpen: boolean;
  items: MenuItemData[];
  level: number;
  path: number[];
  setItems?: React.Dispatch<React.SetStateAction<MenuItemData[]>>;
}

const nestedMenuItemsFromObject = ({
  handleClose,
  isOpen,
  items,
  level,
  path,
  setItems,
}: nestedMenuItemsFromObjectProps) => {
  return items.map((item, levelIndex) => {
    const { leftIcon, rightIcon, label, items, callback, sx, disabled, checked, indeterminate } =
      item;

    const newPath = [...path, levelIndex];

    if (items && items.length > 0) {
      // Recurse deeper
      return (
        <NestedMenuItem
          checked={checked}
          indeterminate={indeterminate}
          disabled={disabled}
          key={label}
          label={label}
          leftIcon={leftIcon}
          parentMenuOpen={isOpen}
          path={newPath}
          rightIcon={rightIcon}
          setItems={setItems}
          sx={sx}
        >
          {/* Call this function to nest more items */}
          {nestedMenuItemsFromObject({
            handleClose,
            isOpen,
            items,
            level: level + 1,
            setItems,
            path: newPath,
          })}
        </NestedMenuItem>
      );
    } else {
      // No children elements, return MenuItem
      return (
        <IconMenuItem
          key={label}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          label={label}
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            if (checked === undefined) handleClose();
            callback && callback(event, item);
          }}
          sx={sx}
          disabled={disabled}
          checked={checked}
          indeterminate={indeterminate}
          setItems={setItems}
          path={newPath}
        />
      );
    }
  });
};

export const NestedDropdown = forwardRef<HTMLDivElement | null, NestedDropdownProps>(
  function NestedDropdown(props, ref) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const { items, label, setItems, onClick, ButtonProps, MenuProps, ...rest } = props;

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(e.currentTarget);
      onClick && onClick(e);
    };
    const handleClose = () => setAnchorEl(null);

    const path: number[] = [];

    const menuItems = nestedMenuItemsFromObject({
      handleClose,
      isOpen: open,
      items: items ?? [],
      level: 0,
      setItems,
      path,
    });

    return (
      <div ref={ref} {...rest}>
        <Button onClick={handleClick} endIcon={<ExpandMoreRounded />} {...ButtonProps}>
          {label ?? "Menu"}
        </Button>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} {...MenuProps}>
          {menuItems}
        </Menu>
      </div>
    );
  }
);
