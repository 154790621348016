import { CSSProperties, FC } from "react";

export type PlayStoreLogoProps = {
  iconsStyle: CSSProperties;
};

const PlayStoreLogo: FC<PlayStoreLogoProps> = ({ iconsStyle }) => (
  // eslint-disable-next-line @next/next/no-img-element
  <img
    style={iconsStyle}
    src="https://dialonce-cdn.s3.eu-central-1.amazonaws.com/img/dialonce/google_play.jpg"
    alt="Logo de Google Play"
  />
);

export default PlayStoreLogo;
