import { forwardRef } from "react";
import { TooltipProps as MUITooltipProps, Tooltip as MUITooltip, Zoom } from "@mui/material";

export type TooltipProps = MUITooltipProps;

export const Tooltip = forwardRef<unknown, TooltipProps>((props, ref) => {
  const { arrow = true, TransitionComponent = Zoom } = props;

  return (
    <MUITooltip ref={ref} {...props} arrow={arrow} TransitionComponent={TransitionComponent} />
  );
});

export default Tooltip;
