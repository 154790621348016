import { forwardRef } from "react";
import type { BorderColor, BorderRadius, Elevation } from "../../types";

import { Box as MUIBox, BoxProps as MUIBoxProps } from "@mui/material";
import { borderColorConverter, borderRadiusConverter, elevationConverter } from "../../constants";

export type BoxProps = Omit<MUIBoxProps, "border"> & {
  rounded?: BorderRadius;
  border?: boolean;
  borderTheme?: BorderColor;
  elevation?: Elevation;
};

export const Box = forwardRef<unknown, BoxProps>(
  ({ rounded, border = false, borderTheme = "light", elevation, ...props }, ref) => {
    return (
      <MUIBox
        ref={ref}
        {...(border && {
          border: 1,
          borderColor: borderColorConverter[borderTheme],
        })}
        borderRadius={rounded ? borderRadiusConverter[rounded] : undefined}
        boxShadow={elevation ? elevationConverter[elevation] : undefined}
        {...props}
      />
    );
  }
);
