import { Signal } from "@preact/signals-react";
import { z } from "zod";

import { ContextOperations, NodeType } from "../../types/api";
import { Action } from "../../types/common";
import { ContextKeysRecord } from "../../types/scenarioDetails";
import { GenericObject, scenarioParams } from "../stores";
import { updateContrackContext } from "./requests";

export function isElementDisplayableAccordingToContext(
  contextKeys: ContextKeysRecord,
  context: GenericObject,
  elementContextId: string
) {
  const { items } = contextKeys;
  const contextObject = items.find((element) => element._id === elementContextId);

  if (!contextObject) {
    return true;
  }

  const { value } = contextObject;

  return value in context;
}

const transformationFunctionSchema = z.object({
  view: z.string(),
  actionValues: z.record(z.string(), z.unknown()).optional(),
});

export type TransformationFunctionResult = z.infer<typeof transformationFunctionSchema>;

export function runTransformationFunction(
  serializedFunction: string,
  context: GenericObject,
  type: NodeType,
  view: string,
  action?: Action
): TransformationFunctionResult | undefined {
  try {
    const unserializedFunction = new Function("return " + serializedFunction)();

    const result = unserializedFunction(context, type, view, action);

    const validation = transformationFunctionSchema.safeParse(result);

    if (!validation.success) {
      throw new Error("Transform function does not respect expected output format");
    }

    return result;
  } catch (error: unknown) {
    // eslint-disable-next-line no-console
    console.error("Failed to run transformation function : %o", error);
    return;
  }
}

export function applyContextOperationsToContext(
  context: Signal<GenericObject>,
  operations: ContextOperations
) {
  operations.keysToUpsert.forEach(({ key, value }) => {
    context.value[key] = value;
  });

  operations.keysToDelete.forEach((key) => {
    delete context.value[key];
  });
}

export async function syncContextWithContrack(context: GenericObject, shortCode: string) {
  try {
    const { pinCode } = scenarioParams.value;

    if (!pinCode) {
      console.warn(`Cannot update context for short code ${shortCode} as there is no pin code`);
      return;
    }

    await updateContrackContext(shortCode, pinCode, context);
  } catch (error) {
    console.error(
      `Failed to sync context with contrack for short code ${shortCode}, error %o`,
      error
    );
  }
}
