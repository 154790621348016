import { forwardRef, useMemo } from "react";
import {
  LinearProgress as MUILinearProgress,
  LinearProgressProps as MUILinearProgressProps,
  CircularProgress as MUICircularProgress,
  CircularProgressProps as MUICircularProgressProps,
  linearProgressClasses,
  circularProgressClasses,
  styled,
} from "@mui/material";
import { Box } from "../layout";
import Text from "../data-display/Text";

export type ProgressProps = Omit<
  MUILinearProgressProps & MUICircularProgressProps,
  "color" | "variant" | "valueBuffer" | "thickness"
> & {
  type?: "linear" | "circular";
  color?: "primary" | "secondary" | "inherit";
  variant?: "determinate" | "indeterminate";
  labelPosition?: "top" | "bottom" | "left" | "right" | "default";
  labelType?: "percentage" | "text";
  labelText?: string;
  gradient?: string;
};

const MUIBorderLinearProgress = styled(MUILinearProgress)<{ gradient?: string }>(
  ({ theme, gradient }) => ({
    height: 6,
    borderRadius: 3,
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],

    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 3,
      background: gradient ? `${gradient})` : "",
    },
  })
);

const MUIRoundedCircularProgress = styled(MUICircularProgress)(() => ({
  [`& .${circularProgressClasses.circle}`]: {
    strokeLinecap: "round",
  },
}));

export const Progress = forwardRef<unknown, ProgressProps>(
  (
    {
      type = "circular",
      color = "primary",
      variant = "indeterminate",
      value,
      size,
      disableShrink,
      labelType = "percentage",
      labelPosition,
      labelText,
      gradient,
      ...props
    },
    ref
  ) => {
    const labelTextDisplay = useMemo(
      () =>
        labelType === "percentage" ? `${Math.round(value ?? 0)}%` : labelText ? labelText : value,
      [value, labelType, labelText]
    );

    return type === "linear" ? (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection:
            labelPosition === "bottom"
              ? "column"
              : labelPosition === "top"
              ? "column-reverse"
              : labelPosition === "left"
              ? "row-reverse"
              : "row",
        }}
      >
        <Box
          sx={{
            width: "100%",
            mr: labelPosition === "right" ? 1 : 0,
            ml: labelPosition === "left" ? 1 : 0,
            mt: labelPosition === "top" ? 1 : 0,
            mb: labelPosition === "bottom" ? 1 : 0,
          }}
        >
          <MUIBorderLinearProgress
            ref={ref}
            value={value}
            color={color}
            variant={variant}
            gradient={gradient}
            {...props}
          />
        </Box>

        {labelPosition && (
          <Box sx={{ minWidth: "2rem", marginBottom: "3px" }}>
            <Text component="span" size="xs" color="grey.500">
              {labelTextDisplay}
            </Text>
          </Box>
        )}
      </Box>
    ) : (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <MUIRoundedCircularProgress
          ref={ref}
          value={value}
          color={color}
          variant={variant}
          disableShrink={disableShrink}
          thickness={5}
          size={size}
          {...props}
        />

        {labelPosition && (
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text component="span" size="xs">
              {labelTextDisplay}
            </Text>
          </Box>
        )}
      </Box>
    );
  }
);
