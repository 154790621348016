import { Box, Typography, CircularProgress } from "@mui/material";
import { FC } from "react";

// Color is not pretty, but Mui has an issue about this : https://github.com/mui/material-ui/issues/24778
export type SpinnerProps = {
  text?: string;
  textColor?: string;
  spinnerColor?: "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
};

const Spinner: FC<SpinnerProps> = ({
  text,
  spinnerColor = "primary",
  textColor = "primary.main",
}) => (
  <Box
    alignItems="center"
    display="flex"
    flex="1 1 auto"
    flexDirection="column"
    justifyContent="center"
  >
    {text && <Typography color={textColor}>Loading...</Typography>}
    <CircularProgress color={spinnerColor} />
  </Box>
);

export default Spinner;
