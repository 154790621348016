import { PageResponse } from "../../../types/api";

import { SCENARIO_SESSION_ID_HEADER_KEY } from "../../../common/constants";
import {
  ScenarioAnalyticsDocumentActionType,
  ScenarioData,
  ScenarioDetails,
  SolutionActionType,
} from "../../../types";
import { STORAGE_SESSION_ID_KEY } from "../../constants";
import { GenericObject } from "../../stores";
import { getInSessionStorage, setInSessionStorage } from "../analytics";

type GetScenarioPageItemParams = {
  scenarioId?: string;
  shortCode?: string;
  pageKey?: string;
  currentIntegrationType: string;
};

export const getScenarioPage = async ({
  scenarioId,
  pageKey,
  shortCode,
  currentIntegrationType: integrationType,
}: GetScenarioPageItemParams) => {
  const params = new URLSearchParams({
    ...(integrationType && { integrationType }),
    ...(shortCode && { shortCode }),
    ...(scenarioId && { scenarioId }),
  });

  const response: { data: PageResponse; message?: string; status: number } = await fetch(
    `/api/scenario/page/${pageKey}?${params}`
  )
    .then(async (res) => ({ status: res.status, ...(await res.json()) }))
    .then((res) => res);

  return handleResponseWithStatusCode<{ scenarioData: PageResponse }>(response.status, {
    scenarioData: response.data,
  });
};

type GetScenarioSolutionPageParams = {
  scenarioId?: string;
  solutionId?: string;
  shortCode?: string;
  currentIntegrationType: string;
};

export const getScenarioSolutionPage = async ({
  scenarioId,
  solutionId,
  shortCode,
  currentIntegrationType: integrationType,
}: GetScenarioSolutionPageParams) => {
  const params = new URLSearchParams({
    ...(integrationType && { integrationType }),
  });

  const response: Response & { data: ScenarioData } = await fetch(
    `/api/scenario/solution/${solutionId}?${params}`
  )
    .then(async (res) => ({ status: res.status, ...(await res.json()) }))
    .then((res) => res);
  return handleResponseWithStatusCode<{ scenarioData: ScenarioData }>(response.status, {
    scenarioData: response.data,
  });
};

type GetScenarioDetailsParams = {
  scenarioId?: string;
  shortCode?: string;
  currentIntegrationType: string;
  needsFirstPage?: boolean;
  code?: string;
};

export const getScenarioDetails = async ({
  scenarioId,
  shortCode,
  currentIntegrationType: integrationType,
  needsFirstPage = false,
}: GetScenarioDetailsParams) => {
  const defaultParams: { [key: string]: string } = {
    ...(integrationType && { integrationType }),
    ...(shortCode && { shortCode }),
    ...(scenarioId && { scenarioId }),
    ...(needsFirstPage && { needsFirstPage: "true" }),
  };
  const params = new URLSearchParams(defaultParams);

  const response: Response & { data: ScenarioDetails } = await fetch(
    `/api/scenario/details?${params}`
  )
    .then(async (res) => ({ status: res.status, ...(await res.json()) }))
    .then((res) => res);
  return handleResponseWithStatusCode<{ scenarioDetails: ScenarioDetails }>(response.status, {
    scenarioDetails: response.data,
  });
};

type IAdvizeDetails = {
  vuid: string;
  conversationId: string;
};

export type PostScenarioDetailsPayload = {
  type: ScenarioAnalyticsDocumentActionType;
  shortCode?: string;
  fromPageKey?: string;
  toPageKey?: string;
  fromIntentId?: string;
  solutionType?: SolutionActionType;
  solutionId?: string;
  IAdvizeDetails?: IAdvizeDetails;
};

export const postScenarioDetails = async (payload: PostScenarioDetailsPayload) => {
  // If no shortCode, we are in preview so no need to send analytics
  if (!payload.shortCode) return;

  const scenarioSessionId = getInSessionStorage(STORAGE_SESSION_ID_KEY);
  const headers = new Headers();
  if (scenarioSessionId) {
    headers.append(SCENARIO_SESSION_ID_HEADER_KEY, scenarioSessionId);
  }
  const response = await fetch("/api/scenario/details", {
    method: "POST",
    headers,
    body: JSON.stringify(payload),
  }).then(async (res) => ({ status: res.status, ...(await res.json()) }));

  if (response.data?.scenarioSessionId) {
    setInSessionStorage(STORAGE_SESSION_ID_KEY, response.data.scenarioSessionId);
  }
  return handleResponseWithStatusCode<string>(response.status, response.data?.scenarioSessionId);
};

const handleResponseWithStatusCode = <TResponse>(status: number, data?: TResponse) => {
  // 2xx is success
  if (status.toString().startsWith("2") && data) return data ?? null;
  else {
    // TODO: handle error codes
    return null;
  }
};

export async function pushContextToContrack(
  pinCode: string,
  shortCode?: string,
  scenarioId?: string
) {
  const urlSearchParams = new URLSearchParams();
  // In practice scenarioId seem to never be null here, but it is typed null in the whole call stack.
  // So for now we just push an empty string and let contrack do the error logging.
  // Let's fix somewhere scenarioId's typing in scenario-viewer.
  urlSearchParams.set("scenarioId", scenarioId ?? "");
  urlSearchParams.set("shortCode", shortCode ?? "");

  return fetch(`/api/context/${pinCode}/push?${urlSearchParams.toString()}`, {
    method: "post",
  });
}

export async function getContextFromContrack(shortCode: string, pinCode: string) {
  return fetch(`/api/context/${pinCode}/${shortCode}`);
}

export async function updateContrackContext(
  shortCode: string,
  pinCode: string,
  context: GenericObject
) {
  return fetch(`/api/context/${pinCode}/${shortCode}`, {
    method: "PATCH",
    body: JSON.stringify({ context }),
  });
}
