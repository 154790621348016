import React, { FC, forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import {
  Accept as DropZoneAccept,
  useDropzone,
  FileError,
  FileWithPath,
  ErrorCode,
  DropEvent,
  FileRejection,
} from "react-dropzone";
import {
  dropzoneAcceptStyle,
  dropzoneBaseStyle,
  dropzoneDisabledStyle,
  dropzoneErrorStyle,
  dropzoneFocusedStyle,
  dropzoneNoClickStyle,
  dropzoneRejectStyle,
} from "../../constants";
import { SxProps, useTheme } from "@mui/material";
import { ThemeType } from "../../theme";
import { Language } from "../../types/language";
import { defaultLanguage } from "../../constants/language";
import { Box, Stack } from "../layout";
import { Text } from "../data-display";
import {
  SlideshowRounded,
  ImageOutlined,
  InsertDriveFileOutlined,
  UploadFileOutlined,
  CloseRounded,
} from "@mui/icons-material";
import { SystemCssProperties } from "@mui/system";

type AcceptType = "application" | "audio" | "image" | "text" | "video";

type Accept = {
  type: AcceptType;
  extensions: string[];
};

export type DropZoneFiles = File[] | null;

export type DropZoneProps = {
  accepts?: Accept[];
  autoFocus?: boolean;
  disabled?: boolean;
  displayAccept?: "inside" | "outside" | "none";
  displayDropZone?: boolean;
  error?: boolean;
  extensionsInPlaceholder?: boolean;
  errorMessages?: {
    size: string;
    type: string;
    tooManyFiles: string;
  };
  fullWidth?: boolean;
  language?: Language;
  maxFiles?: number;
  maxSize?: number;
  minSize?: number;
  multiple?: boolean;
  noClick?: boolean;
  noDrag?: boolean;
  noKeyboard?: boolean;
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => Promise<unknown>;
  placeholder?: string;
  required?: boolean;
  setValue?: (value: DropZoneFiles) => void;
  sx?: SxProps;
  value?: DropZoneFiles;
};

const acceptExtensionFormats = {
  doc: "msword",
  docx: "vnd.openxmlformats-officedocument.wordprocessingml.document",
  txt: "plain",
  svg: "svg+xml",
};

const formatExtension = (extension: string) => {
  return acceptExtensionFormats[extension as keyof typeof acceptExtensionFormats] ?? extension;
};

const getAcceptTypeFormat = (accepts: Accept[]): DropZoneAccept => {
  const formattedAccepts: DropZoneAccept = {};

  accepts.forEach((accept) => {
    if (accept.extensions.length === 0) {
      formattedAccepts[`${accept.type}/*}`] = [];
    } else {
      accept.extensions.forEach((extension) => {
        formattedAccepts[`${accept.type}/${formatExtension(extension.substring(1))}`] = [];
      });
    }
  });

  return formattedAccepts;
};

const deduplicateArray = (values: File[]) => {
  const seenDuringDeduplicate = new Set();

  const uniqueFiles = values.reverse().filter((file) => {
    // .reverse() to keep the lastModified one if duplicate
    const duplicate = seenDuringDeduplicate.has(file.name);
    seenDuringDeduplicate.add(file.name);

    return !duplicate;
  });

  return uniqueFiles;
};

export const DropZone: FC<DropZoneProps> = forwardRef<HTMLDivElement, DropZoneProps>(
  (
    {
      accepts,
      autoFocus,
      disabled,
      displayAccept = "none",
      displayDropZone = true,
      extensionsInPlaceholder,
      error,
      errorMessages,
      fullWidth = false,
      language = defaultLanguage,
      maxFiles,
      maxSize,
      minSize,
      multiple,
      noClick,
      noDrag,
      noKeyboard,
      onDrop,
      placeholder,
      required,
      setValue,
      sx,
      value,
    },
    ref
  ) => {
    const theme: ThemeType = useTheme();
    const { fileRejections, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
      useDropzone({
        accept: accepts ? getAcceptTypeFormat(accepts) : undefined,
        autoFocus,
        disabled,
        maxFiles,
        maxSize,
        minSize,
        multiple,
        noClick,
        noDrag,
        noKeyboard,
        onDrop: (acceptedFiles, fileRejections, event) => {
          if (setValue && value) {
            if (multiple) {
              const allFiles = [...(value || []), ...acceptedFiles];
              const uniqueFiles = deduplicateArray(allFiles);

              setValue(uniqueFiles);
            } else {
              setValue(acceptedFiles);
            }
          }

          if (onDrop) {
            void onDrop(acceptedFiles, fileRejections, event);
          }
        },
      });

    const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);

    useEffect(() => {
      setRejectedFiles(fileRejections);
    }, [fileRejections]);

    const traductions = useMemo(
      () => ({
        [Language.en]: {
          placeholder: {
            default: "Drag a file here or",
            button: "import a file",
            error: {
              type: "File type is incorrect",
              size: "File size is incorrect",
              tooManyFiles: "Too many files",
            },
            maxFiles: `${maxFiles} file${(maxFiles || 1) > 1 && "s"} maximum`,
            extension: {
              default: "accept all file extensions",
              only: "only accept",
            },
            multiple: {
              default: "Drag files here or",
              button: "import files",
              error: {
                type: "File types are incorrect",
                size: "File sizes are incorrect",
                tooManyFiles: "Too many files",
              },
              noDrag: "Import files",
              noClick: "Drag files here",
            },
            noClick: "Drag a file here",
            noDrag: "Import a file",
            noDragAndNoClick: "This field is disabled",
          },
        },
        [Language.fr]: {
          placeholder: {
            default: "Faites glisser un fichier ou",
            button: "importez un fichier",
            error: {
              type: "Type de fichier incorrect",
              size: "Taille de fichier incorrecte",
              tooManyFiles: "Trop de fichiers",
            },
            maxFiles: `${maxFiles} fichier${(maxFiles || 1) > 1 && "s"} maximum`,
            extension: {
              default: "toutes les extensions de fichiers sont acceptées",
              only: "seulement les extensions",
            },
            multiple: {
              default: "Faites glisser des fichiers ici ou",
              button: "importez des fichiers",
              error: {
                type: "Les types de fichiers sont incorrects",
                size: "Les tailles de fichiers sont incorrectes",
                tooManyFiles: "Trop de fichiers",
              },
              noDrag: "Importez des fichiers",
              noClick: "Faites glisser des fichiers ici",
            },
            noClick: "Faites glisser un fichier ici",
            noDrag: "Importez un fichier",
            noDragAndNoClick: "Ce champ est désactivé",
          },
        },
      }),
      [maxFiles]
    );

    const fileTypeErrorMessage =
      errorMessages?.type ?? traductions[language].placeholder.error.type;
    const fileSizeErrorMessage =
      errorMessages?.size ?? traductions[language].placeholder.error.size;
    const fileTooManyFilesErrorMessage =
      errorMessages?.tooManyFiles ?? traductions[language].placeholder.error.tooManyFiles;

    const getExtensionsPlaceholder = useCallback(() => {
      let placeholder = traductions[language].placeholder.extension.default;
      let allExtensions: Accept["extensions"] = [];

      if (accepts && accepts.length > 0) {
        accepts.forEach((accept) => {
          allExtensions = [...allExtensions, ...accept.extensions];
        });
        placeholder =
          traductions[language].placeholder.extension.only + " " + allExtensions.join(", ");
      }

      return placeholder;
    }, [accepts, language, traductions]);

    const SimpleText = ({ children }: { children: React.ReactNode }) => (
      <Text
        color="inherit"
        component="span"
        sx={{
          display: "inline",
        }}
      >
        {children}
      </Text>
    );

    const ExtensionText = ({ children }: { children: React.ReactNode }) => (
      <Text
        component="span"
        sx={{ display: "block", fontStyle: "italic" }}
        color="inherit"
        size="sm"
      >
        {children}
      </Text>
    );

    const ButtonText = useCallback(
      ({ children }: { children: React.ReactNode }) => (
        <Text
          component="span"
          color={error ? "error" : "primary"}
          sx={{
            textDecoration: "underline",
            display: "inline",
          }}
        >
          {children}
        </Text>
      ),
      [error]
    );

    const getIconFile = useCallback((file: File) => {
      let Icon;

      if (file.type.startsWith("image")) {
        Icon = ImageOutlined;
      } else if (file.type.startsWith("video")) {
        Icon = SlideshowRounded;
      } else {
        Icon = InsertDriveFileOutlined;
      }

      return Icon;
    }, []);

    function formatFileSize(size: number) {
      const KB = 1024;
      const MB = 1024 * KB;
      const GB = 1024 * MB;

      if (size < KB) {
        return size + " octets";
      } else if (size < MB) {
        return (size / KB).toFixed(2) + " KB";
      } else if (size < GB) {
        return (size / MB).toFixed(2) + " MB";
      } else {
        return (size / GB).toFixed(2) + " GB";
      }
    }

    const getAcceptedFileItems = useCallback(() => {
      const acceptedFileItems = value?.map((droppedFile: FileWithPath) => {
        const Icon = getIconFile(droppedFile);

        return (
          <Stack
            alignItems="center"
            component="li"
            direction="row"
            justifyContent="space-between"
            key={droppedFile.path}
            spacing={1}
            sx={{ listStyle: "none" }}
          >
            <Stack alignItems="center" direction="row" flex="1">
              <Icon color="primary" sx={{ fontSize: 28, marginRight: 1 }} />
              <Stack direction="row" flex="1" spacing={2}>
                <Text component="span" variant="label" maxWidth="300px" truncate>
                  {droppedFile.path}
                </Text>
                <Text
                  color="grey.500"
                  component="span"
                  flex="1"
                  textAlign="right"
                  variant="label"
                  whiteSpace="nowrap"
                >
                  {formatFileSize(droppedFile.size)}
                </Text>
              </Stack>
            </Stack>

            {displayDropZone && (
              <CloseRounded
                sx={{
                  fontSize: 20,
                  marginLeft: 1,
                  color: theme.palette.grey[400],
                  cursor: "pointer",
                  "&:hover": { color: theme.palette.grey[600] },
                }}
                role="button"
                onClick={(e) => {
                  e.stopPropagation();

                  if (setValue && value) {
                    setValue(value.filter((f) => f !== droppedFile));
                  }
                }}
              />
            )}
          </Stack>
        );
      });

      return (
        <Stack direction="column" spacing={1} component="ul" sx={{ padding: 0, margin: 0 }}>
          {acceptedFileItems}
        </Stack>
      );
    }, [displayDropZone, getIconFile, setValue, value, theme.palette.grey]);

    const getFileRejectionItems = useCallback(() => {
      const fileRejectionItems = rejectedFiles.map(
        ({ file, errors }: { file: FileWithPath; errors: FileError[] }) => {
          const Icon = getIconFile(file);

          return (
            <Stack
              alignItems="center"
              component="li"
              direction="row"
              justifyContent="space-between"
              key={file.path}
              spacing={1}
              sx={{
                color: theme.palette.error.main,
                listStyle: "none",
              }}
            >
              <Stack alignItems="center" direction="row" flex="1">
                <Icon color="inherit" sx={{ fontSize: 28, marginRight: 1 }} />
                <Stack direction="row" flex="1" spacing={1}>
                  <Text component="span" variant="label" maxWidth="300px" color="inherit" truncate>
                    {file.path}
                  </Text>
                  <Text
                    color="inherit"
                    component="span"
                    flex="1"
                    textAlign="right"
                    variant="label"
                    whiteSpace="nowrap"
                  >
                    {errors
                      .map((e) => {
                        switch (e.code) {
                          case ErrorCode.FileInvalidType:
                            return fileTypeErrorMessage;
                          case ErrorCode.FileTooLarge:
                          case ErrorCode.FileTooSmall:
                            return fileSizeErrorMessage;
                          case ErrorCode.TooManyFiles:
                            return fileTooManyFilesErrorMessage;
                          default:
                            return e.message;
                        }
                      })
                      .join(", ")}
                  </Text>
                </Stack>
              </Stack>

              {displayDropZone && (
                <CloseRounded
                  sx={{
                    fontSize: 20,
                    marginLeft: 1,
                    color: theme.palette.error.main,
                    cursor: "pointer",
                    "&:hover": { color: theme.palette.error.dark },
                  }}
                  role="button"
                  onClick={(e) => {
                    e.stopPropagation();

                    if (setRejectedFiles) {
                      setRejectedFiles((prev) => (prev ? prev.filter((f) => f.file !== file) : []));
                    }
                  }}
                />
              )}
            </Stack>
          );
        }
      );

      return (
        <Stack
          direction="column"
          spacing={1}
          component="ul"
          sx={{ padding: 0, marginBottom: 0, marginTop: 1 }}
        >
          {fileRejectionItems}
        </Stack>
      );
    }, [
      displayDropZone,
      fileRejections,
      fileTypeErrorMessage,
      fileSizeErrorMessage,
      fileTooManyFilesErrorMessage,
      getIconFile,
      rejectedFiles,
      theme,
    ]);

    const Files = useCallback(() => {
      return (
        <Stack direction="column">
          {(value?.length || 0) > 0 && getAcceptedFileItems()}
          {rejectedFiles.length > 0 && getFileRejectionItems()}
        </Stack>
      );
    }, [value, getAcceptedFileItems, getFileRejectionItems, rejectedFiles]);

    const getPlaceholder = useCallback(() => {
      let simplePlaceholder = "",
        buttonPlaceholder = "",
        mainPlaceholder;

      if (multiple) {
        simplePlaceholder = traductions[language].placeholder.multiple.default;
        buttonPlaceholder = traductions[language].placeholder.multiple.button;
      } else {
        simplePlaceholder = traductions[language].placeholder.default;
        buttonPlaceholder = traductions[language].placeholder.button;
      }

      if (noClick && noDrag) {
        mainPlaceholder = (
          <SimpleText>
            {traductions[language].placeholder.noDragAndNoClick} {required && " *"}
          </SimpleText>
        );
      } else if (noClick) {
        if (multiple) {
          mainPlaceholder = (
            <SimpleText>
              {traductions[language].placeholder.multiple.noClick} {required && " *"}
            </SimpleText>
          );
        } else {
          mainPlaceholder = (
            <SimpleText>
              {traductions[language].placeholder.noClick} {required && " *"}
            </SimpleText>
          );
        }
      } else if (noDrag) {
        if (multiple) {
          mainPlaceholder = (
            <ButtonText>
              {traductions[language].placeholder.multiple.noDrag} {required && " *"}
            </ButtonText>
          );
        } else {
          mainPlaceholder = (
            <ButtonText>
              {traductions[language].placeholder.noDrag} {required && " *"}
            </ButtonText>
          );
        }
      }

      const complementaryPlaceholder = extensionsInPlaceholder ? (
        <ExtensionText>{getExtensionsPlaceholder()}</ExtensionText>
      ) : undefined;

      return (
        <Stack direction="column">
          {placeholder
            ? `${placeholder}${required ? " *" : ""}`
            : mainPlaceholder ?? (
                <>
                  <SimpleText>{simplePlaceholder}</SimpleText>

                  <Box sx={maxFiles || complementaryPlaceholder ? { marginBottom: 1 } : undefined}>
                    <ButtonText>{buttonPlaceholder}</ButtonText>
                    <SimpleText>{required && " *"}</SimpleText>
                  </Box>
                </>
              )}

          {maxFiles && (
            <ExtensionText>{`${traductions[language].placeholder.maxFiles}`}</ExtensionText>
          )}

          {complementaryPlaceholder}
        </Stack>
      );
    }, [
      ButtonText,
      extensionsInPlaceholder,
      getExtensionsPlaceholder,
      language,
      maxFiles,
      multiple,
      noClick,
      noDrag,
      placeholder,
      required,
      traductions,
    ]);

    const style = useMemo(
      () =>
        ({
          ...dropzoneBaseStyle(theme),
          ...(isFocused ? dropzoneFocusedStyle(theme) : {}),
          ...(isDragAccept ? dropzoneAcceptStyle(theme) : {}),
          ...(noClick ? dropzoneNoClickStyle(theme) : {}),
          ...(disabled ? dropzoneDisabledStyle(theme) : {}),
          ...(rejectedFiles.length > 0 ? dropzoneErrorStyle(theme) : {}),
          ...(isDragReject ? dropzoneRejectStyle(theme) : {}),
          ...(error ? dropzoneErrorStyle(theme) : {}),
        } as React.CSSProperties),
      [disabled, error, isFocused, isDragAccept, isDragReject, noClick, rejectedFiles, theme]
    );

    const width = fullWidth ? "100%" : (sx as SystemCssProperties)?.width ?? "500px";

    return (
      <Stack width={fullWidth ? "100%" : undefined}>
        {displayDropZone && (
          <Box
            ref={ref}
            sx={{
              width,
              ...sx,
            }}
            {...getRootProps({ style })}
          >
            <input {...getInputProps()} />

            <Box display="flex" alignItems="center">
              {((value?.length === 0 && fileRejections.length === 0) ||
                displayAccept !== "inside") && (
                <Stack direction="row" spacing={1.5} alignItems="center">
                  <UploadFileOutlined
                    sx={{
                      fontSize: 48,
                      color: theme.palette.primary.main,
                      margin: "auto",
                      display: "block",
                    }}
                  />
                  {getPlaceholder()}
                </Stack>
              )}

              {displayAccept === "inside" && <Files />}
            </Box>
          </Box>
        )}

        {displayAccept === "outside" && (value?.length || rejectedFiles?.length) ? (
          <Box
            sx={{
              marginTop: 3,
              width,
              marginX: "auto",
            }}
          >
            <Files />
          </Box>
        ) : null}
      </Stack>
    );
  }
);
