import { forwardRef } from "react";
import { DividerProps as MUIDividerProps, Divider as MUIDivider } from "@mui/material";

export type DividerProps = MUIDividerProps;

export const Divider = forwardRef<HTMLHRElement, DividerProps>((props, ref) => {
  return <MUIDivider ref={ref} {...props} />;
});

export default Divider;
